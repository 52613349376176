export function isEmptyArray(array?: Array<any>) {
    return (array ?? []).length === 0
}

export function isNotEmptyArray(array: Array<any>) {
    return !isEmptyArray(array)
}

export function getNumberRange(from: number, to: number): number[] {
    return new Array(to - from + 1).fill('').map((v, i) => i + from)
}
